import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
  static targets = ["wrapper", "body"]
  static values = {
    skipSubmitClose: Boolean
  }

  connect() {
    this.open()

    if (!this.skipSubmitCloseValue) {
      document.addEventListener('turbo:submit-end', this.handleSubmit)
    }
  }

  disconnect() {
    if (!this.skipSubmitCloseValue) {
      document.removeEventListener('turbo:submit-end', this.handleSubmit)
    }
  }

  open() {
    enter(this.wrapperTarget)
    enter(this.bodyTarget)

    // Disable scroll on body
    document.body.style.overflow = "hidden"
  }

  close(e) {
    if (e) { e.preventDefault() }

    leave(this.wrapperTarget)
    leave(this.bodyTarget).then(() => {
      // Remove the modal element after the fade out so it doesn't blanket the screen
      this.element.remove()
    })

    // Remove src reference from parent frame element
    // Without this, turbo won't re-open the modal on subsequent clicks
    this.element.closest("turbo-frame").src = undefined

    // enable scroll on body
    document.body.style.overflow = ""
  }

  handleKeyup(e) {
    if (e.code == "Escape") {
      this.close()
    }
  }

  handleSubmit = (e) => {
    if (e.detail.success) {
      this.close()
    }
  }
}
