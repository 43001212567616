// live_commenting_related
import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  initialize() {
    this.subscription = null
    this.subscriptionKey = this.data.get("subscriptionKey")
    this.path = this.data.get("path")
  }

  connect() {
    this.element[this.identifier] = this

    if (this.subscription === null) {
      this.subscription = consumer.subscriptions.create({
        channel: "MeetingTocChannel",
        key: this.subscriptionKey
      },
      {
        connected: function(data) {
          Sockets.MeetingTocChannel = this
        },
        disconnected: function(data) {
          Sockets.MeetingTocChannel = null
        },
        rejected: (data) => {
          console.log('mtc rejected data: ', data)
        },
        received: (data) => {
          window.replaceElementWithPartial(this.element.children[0], data.html)
        }
      })
    }
  }

  disconnect() {
    this.subscription.unsubscribe()
    this.subscription = null
  }

  refetchAndRender() {
    fetch(`${this.path}/partials?partial_name=shared/meeting_toc`, {
      method: "GET",
      credentials: "same-origin",
      headers:  {},
    })
      .then(response => response.text())
      .then(html => {
        window.replaceElementWithPartial(this.element.children[0], html)
      })
      .catch(console.error)
  }

  // sent via socket-js-polling-fallback controller
  handleFallbackData() {
    this.refetchAndRender()
  }

  // sent via MeetingToc controller on clicking item
  resubscribeToMeeting(meetingId) {
    // item_ID_toc, item_ID_toc_dm_ID, meeting_ID_toc, meeting_ID_toc_dm_ID
    var subscriptionParts = this.subscriptionKey.split("_")
    subscriptionParts[0] = "meeting"
    subscriptionParts[1] = meetingId
    this.subscriptionKey = subscriptionParts.join("_")
    this.disconnect()
    this.connect()
  }

  // sent via MeetingToc controller on clicking item
  resubscribeToItem(itemId) {
    // item_ID_toc, item_ID_toc_dm_ID, meeting_ID_toc, meeting_ID_toc_dm_ID
    var subscriptionParts = this.subscriptionKey.split("_")
    subscriptionParts[0] = "item"
    subscriptionParts[1] = itemId
    this.subscriptionKey = subscriptionParts.join("_")
    this.disconnect()
    this.connect()
  }

}