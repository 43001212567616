// live_commenting_related
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return [
      "UnreadNotificationCount",
      "UnreadNotificationLabel",
      "UpArrow",
      "DownArrow",
    ]
  }

  SELECTORS() {
    return {
      itemScrollContainer: "#content-pane",
      commentItemContainer: ".comment-item",
      commentListContainer: "#comments-list",
      commentScrollAnchor: "#comment-scroll-anchor",
    }
  }

  setIsPageScrollable() {
    var elem = $(this.SELECTORS().itemScrollContainer)[0]
    if (this.isMobileSized) {
      this.isPageScrollable = elem.scrollHeight >= elem.clientHeight
    } else {
      this.isPageScrollable = elem.scrollHeight > elem.clientHeight
    }
  }

  setExistingCommentCount() {
    $(this.SELECTORS().commentItemContainer).each((_, elem) => {
      if ($(elem).data("commentId")) {
        this.existingCommentIds.push($(elem).data("commentId"))
      }
    })
  }

  setMobileWidthClass() {
    if (this.isMobileSized) {
      this.element.classList.add("w-full")
    } else {
      this.element.classList.remove("w-full")
    }
  }

  setScrollElement() {
    if (this.isMobileSized) {
      this.scrollElement = document
    } else {
      this.scrollElement = this.SELECTORS().itemScrollContainer
    }
  }

  setScrollElementEvent() {
    $(this.SELECTORS().itemScrollContainer).off('scroll')
    $(document).off('scroll')

    $(this.scrollElement).on('scroll', (e) => {
      if (!this.scrollTicking) {
        window.requestAnimationFrame(() => {
          this.onScroll(e)
          this.scrollTicking = false
        })

        this.scrollTicking = true
      }
    })
  }

  initialize() {
    this.itemId = JSON.parse(this.data.get("itemId"))
    this.existingCommentIds = []
    this.scrollTicking = false
    this.isPageScrollable = false
    this.newCommentsCount = 0
    this.showingNotification = false
    this.animationFrame = null
    this.scrollElement = null
    this.isMobileSized = window.innerWidth < 640

    this.setExistingCommentCount()
    this.setIsPageScrollable()
    this.setMobileWidthClass()
    this.setScrollElement()
    this.setScrollElementEvent()

    window.onresize = (event) => {
      this.updateMobileSizedOnFrame()
    }
  }

  connect() {
    this.element[this.identifier] = this
  }

  disconnect() {
    window.onresize = null
    $(this.SELECTORS().itemScrollContainer).off('scroll')
    $(document).off('scroll')
  }

  onScroll(e) {
    if (this.showingNotification) {
      if (window.elementInViewport(this.SELECTORS().commentScrollAnchor)) {
        this.hideUnreadNotification()
      }
    }
  }

  updateMobileSizedOnFrame() {
    if (this.animationFrame) {
      window.cancelAnimationFrame(this.animationFrame)
    }

    this.animationFrame = window.requestAnimationFrame(() => {
      var previousMobile = this.isMobileSized
      this.isMobileSized = window.innerWidth < 640

      if (previousMobile !== this.isMobileSized) {
        this.setMobileWidthClass()
        this.setScrollElement()
        this.setScrollElementEvent()
      }
    })
  }

  onUnreadNotificationClick() {
    let item = $(this.SELECTORS().commentScrollAnchor)[0] // what we want to scroll to
    let wrapper = $(this.SELECTORS().itemScrollContainer)[0] // the wrapper we will scroll inside
    let count = item.offsetTop - wrapper.scrollTop - 200 // any extra distance from top ex. 60
    if (this.isMobileSized) {
      item.scrollIntoView({ behavior: 'smooth' })
    } else {
      wrapper.scrollBy({top: count, left: 0, behavior: 'smooth'})
    }

    this.scrolledBelowTop = false
  }

  handleLiveUpdateData(meetingData) {
    var matchingItem = meetingData.items.find((item) => item.id === this.itemId)

    if (matchingItem) {
      var itemCommentIds = matchingItem.comments.map((comment) => comment.id)
      var newCommentIds = itemCommentIds.filter((cid) => !this.existingCommentIds.includes(cid))
      var removingComments = this.existingCommentIds.filter((cid) => !itemCommentIds.includes(cid))

      if (removingComments.length) {
        removingComments.map((removingComment) => {
          this.existingCommentIds = this.existingCommentIds.filter((cid) => cid !== removingComment)
        })
      }

      if (newCommentIds.length || removingComments.length) {
        this.newCommentsCount = newCommentIds.length - removingComments.length
        newCommentIds.map((id) => this.existingCommentIds.push(id))
        this.setIsPageScrollable()

        var commentAnchorClientRectTop = $(this.SELECTORS().commentScrollAnchor)[0].getBoundingClientRect().top
        var commentAnchorTopInViewButHidden = commentAnchorClientRectTop > 60 && commentAnchorClientRectTop < 120
        if (!window.elementInViewport(this.SELECTORS().commentScrollAnchor) || commentAnchorTopInViewButHidden) {
          this.showUnreadNotification()
        }
      }
    }
  }

  showUnreadNotification() {
    var existingNewCommentCount = parseInt(this.UnreadNotificationCountTarget.innerHTML)
    var newCommentCount = existingNewCommentCount + this.newCommentsCount
    $(this.UnreadNotificationCountTarget).html(newCommentCount)

    if (newCommentCount === 0) {
      // if added, then removed
      this.hideUnreadNotification()
      return
    }
    if (newCommentCount === 1) {
      $(this.UnreadNotificationLabelTarget).html("comment")
    } else {
      $(this.UnreadNotificationLabelTarget).html("comments")
    }

    var commentListInView = window.elementInViewport(this.SELECTORS().commentListContainer) || $(this.SELECTORS().commentListContainer)[0].getBoundingClientRect().top < 0
    var commentAnchorClientRectTop = $(this.SELECTORS().commentScrollAnchor)[0].getBoundingClientRect().top
    var commentAnchorTopInViewButHidden = commentAnchorClientRectTop > 60 && commentAnchorClientRectTop < 120

    if (!this.isPageScrollable) {
      this.UpArrowTarget.classList.remove("hidden")
      this.DownArrowTarget.classList.add("hidden")
      this.element.style.top = 'auto'
      this.element.style.bottom = '140px'
      setTimeout(() => {
        if (!this.isPageScrollable) {
          this.hideUnreadNotification()
        }
      }, 10000)
    } else if (!commentListInView && !commentAnchorTopInViewButHidden) {
      this.UpArrowTarget.classList.add("hidden")
      this.DownArrowTarget.classList.remove("hidden")
      this.element.style.top = 'auto'
      this.element.style.bottom = '140px'
    } else {
      this.UpArrowTarget.classList.remove("hidden")
      this.DownArrowTarget.classList.add("hidden")
      this.element.style.top = '200px'
      this.element.style.bottom = 'auto'
    }

    this.element.classList.remove("hidden")
    this.showingNotification = true
  }

  hideUnreadNotification() {
    this.element.classList.add("hidden")
    $(this.UnreadNotificationCountTarget).html("0")
    this.showingNotification = false
  }

}