import { Controller } from "@hotwired/stimulus"
import {enter, leave} from 'el-transition'

export default class extends Controller {
  static targets = ["CloseArrows", "OpenArrows", "MeetingContainer", "ItemsContainer"]
  static values = { isOpen: Boolean }

  connect() {
    this.isOpen = this.isOpenValue
  }

  toggleItems(e) {
    if (this.isOpen) {
      this.CloseArrowsTarget.classList.add("hidden")
      this.OpenArrowsTarget.classList.remove("hidden")
      this.MeetingContainerTarget.classList.add("!border-b", "!rounded-b-md")
      this.MeetingContainerTarget.classList.remove("rounded-b-none", "border-b-0")
      leave(this.ItemsContainerTarget)
    } else {
      this.CloseArrowsTarget.classList.remove("hidden")
      this.OpenArrowsTarget.classList.add("hidden")
      this.MeetingContainerTarget.classList.remove("!border-b", "!rounded-b-md")
      this.MeetingContainerTarget.classList.add("rounded-b-none", "border-b-0")
      enter(this.ItemsContainerTarget)
    }

    this.isOpen = !this.isOpen
  }
}