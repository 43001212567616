// live_commenting_related
import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  initialize() {
    this.subscription = null
    this.itemId = JSON.parse(this.data.get("itemId"))
  }

  connect() {
    this.element[this.identifier] = this

    if (this.subscription === null) {
      this.subscription = consumer.subscriptions.create({
        channel: "LiveCommentTimerSyncChannel",
        key: `item_${this.itemId}`
      },
      {
        connected: function(data) {
          Sockets.LiveCommentTimerSyncChannel = this
        },
        disconnected: function(data) {
          Sockets.LiveCommentTimerSyncChannel = null
        },
        rejected: (data) => {
          console.log('lctss rejected data: ', data)
        },
        received: (data) => {
          var json = JSON.parse(data)
          this.setTimerValue(json.item.seconds_until_comments_disabled)
        }
      })
    }
  }

  disconnect() {
    this.subscription.unsubscribe()
    this.subscription = null
  }

  setTimerValue(secondsUntilDisabled) {
    var disabledAtMin = Math.floor(secondsUntilDisabled / 60.0)
    var disabledAtSec = Math.floor(secondsUntilDisabled - (disabledAtMin * 60.0)).toString().padStart(2, '0')

    if (disabledAtMin <= 0 && disabledAtSec <= 0) {
      this.element.innerHTML = '0:00'
    } else {
      this.element.innerHTML = disabledAtMin+':'+disabledAtSec
    }

    this.sendDataToControllersWithName("item-subnav-tabs", secondsUntilDisabled)
    this.sendDataToControllersWithName("live-commenting-widget", secondsUntilDisabled)
  }

  refetchAndRender() {
    fetch(`/items/${this.itemId}/timer_sync`, {
      method: "GET",
      credentials: "same-origin",
      headers:  {},
    })
      .then(response => response.json())
      .then(data => {
        this.setTimerValue(data.item.seconds_until_comments_disabled)
      })
      .catch(console.error)
  }

  // sent via socket-js-polling-fallback controller
  handleFallbackData() {
    this.refetchAndRender()
  }

  sendDataToControllersWithName(controllerName, data) {
    $(`[data-controller*='${controllerName}']`).each((_, e) => {
      var elem = document.getElementById($(e).attr('id'))
      var controller = this.application.getControllerForElementAndIdentifier(elem, controllerName)

      if (controller) {
        controller.setSecondsUntilCommentsDisabled(data)
      }
    })
  }

}