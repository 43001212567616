import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
  static targets = ["Element"]
  static values = {
    animateEnter: Boolean,
    animateExit: Boolean
  }

  connect() {
    if (this.animateEnterValue) {
      enter(this.ElementTarget)
    }
  }

  disconnect() {
    if (this.animateExitValue) {
      leave(this.ElementTarget)
    }
  }

}