import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["AgendaContent", "AgendaContentHeading"]
  static values = { currentResourceType: String }

  historyPushStateController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "history-push-state")
  }

  meetingAgendaFilesExpanderController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "meeting-agenda-files-expander")
  }

  onResourceFileClick(e) {
    if (this.currentResourceTypeValue === "Meeting") {
      e.preventDefault()
      e.stopPropagation()

      // expand proper file
      this.meetingAgendaFilesExpanderController().onTocItemClick(e)
    } else {
      this.historyPushStateController().onLinkClick(e)
      const meetingAgendaFilesExpanderController = this.meetingAgendaFilesExpanderController()

      // if going from item page to meeting page, click appropriate link after load
      setTimeout(() => {
        meetingAgendaFilesExpanderController.onTocItemClick(e)
      }, 1000)
    }
  }

  onToggleMobileAgenda(e) {
    e.preventDefault()

    this.AgendaContentTarget.classList.toggle("hidden")
    if (this.currentResourceTypeValue === "Meeting") {
      if (this.hasAgendaContentHeadingTarget) {
        this.AgendaContentHeadingTarget.classList.toggle("hidden")
      }
    }
  }

}