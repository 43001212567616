/* eslint no-console:0 */

import "../stylesheets/application.sass"

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "trix"
import "@rails/actiontext"

window.Sockets = {} // used to hold on to socket connections
if (!window._rails_loaded) {
  Rails.start() // had a double load issue on servers
}
ActiveStorage.start()

import "../../assets/javascripts/lib/element_in_viewport"
import "../../assets/javascripts/lib/replace_element_with_partial"

import "../src/pages"

import "../controllers"

// run code on turbo:load load event
document.addEventListener("turbo:load", function() {
  // console.log('hi from turbo:load in application.js', )
})
