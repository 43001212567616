import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["entries", "loader"]

  connect() {
    this.loading = false
    this.endOfCollection = false
    this.scrollCheck = this.debounce(() => this.checkScroll(), 200)
    window.addEventListener('scroll', this.scrollCheck)
  }

  disconnect() {
    window.removeEventListener('scroll', this.scrollCheck)
  }

  checkScroll() {
    if (window.scrollY >= document.body.offsetHeight - 200) {
      this.loadMore()
    }
  }

  loadMore() {
    if (this.loading || this.endOfCollection) return
    this.loading = true
    this.loaderTarget.classList.remove("hidden")

    let url = new URL(window.location)
    let offset = this.entriesTarget.children.length
    url.searchParams.set('offset', offset)
    url.searchParams.set('limit', 20)

    fetch(url)
      .then(response => response.text())
      .then(html => {
        let parser = new DOMParser()
        let doc = parser.parseFromString(html, 'text/html')
        let newEntriesContainerElem = doc.querySelector(`[data-scroll-pagination-target='entries']`)
        if (newEntriesContainerElem) {
          let newEntries = newEntriesContainerElem.children

          if (newEntries.length < 20) this.endOfCollection = true

          Array.from(newEntries).forEach((child) => {
            this.entriesTarget.appendChild(child)
          })

          this.loading = false
          this.loaderTarget.classList.add("hidden")
        } else {
          this.endOfCollection = true
          this.loading = false
          this.loaderTarget.classList.add("hidden")
        }
      })
  }

  debounce(func, wait) {
    let timeout
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout)
        func(...args)
      }
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }
}