import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["VideoPlaceholder", "ExpandButton", "CollapseButton"]

  sidebarElement() {
    return this.element
  }

  onExpand() {
    this.sidebarElement().classList.remove("sm:w-1/3", "lg:w-1/4")
    this.sidebarElement().classList.add("sm:w-1/2", "md:w-1/2")
    const contentPane = document.querySelector("#content-pane")
    contentPane.classList.remove("sm:w-2/3", "md:w-3/4")
    contentPane.classList.add("sm:w-1/2", "md:w-1/2")
    this.ExpandButtonTarget.classList.add("sm:hidden")
    this.CollapseButtonTarget.classList.remove("hidden")

    this.VideoPlaceholderTarget.classList.remove("sm:h-32", "lg:h-42")
    this.VideoPlaceholderTarget.classList.add("sm:h-54", "lg:h-88")
    this.startResizeLiveVideoInterval()
  }

  onCollapse() {
    this.sidebarElement().classList.add("sm:w-1/3", "lg:w-1/4")
    this.sidebarElement().classList.remove("sm:w-1/2", "md:w-1/2")
    const contentPane = document.querySelector("#content-pane")
    contentPane.classList.add("sm:w-2/3", "md:w-3/4")
    contentPane.classList.remove("sm:w-1/2", "md:w-1/2")
    this.ExpandButtonTarget.classList.remove("sm:hidden")
    this.CollapseButtonTarget.classList.add("hidden")

    this.VideoPlaceholderTarget.classList.remove("sm:h-54", "lg:h-88")
    this.VideoPlaceholderTarget.classList.add("sm:h-32", "lg:h-42")
    this.startResizeLiveVideoInterval()
  }

}