import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    recaptchaId: String,
    siteKey: String
  }

  connect() {
    this.element.id = this.recaptchaIdValue
  }

  initialize() {
    grecaptcha.render(this.recaptchaIdValue, { sitekey: this.siteKeyValue })
  }

}