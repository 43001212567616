// live_commenting_related
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets () {
    return [
      "RejectModal",
      "ReasonOtherContainer",
      "ReasonInput",
      "ReasonOtherInput",
      "SendRejectionEmailInput",
      "DmViewCount",
      "CommentText",
      "CommentInput",
      "ErrorComment",
      "CommentPencilIcon",
      "FullnameText",
      "FullnameInput",
      "ErrorFullname",
      "FullnamePencilIcon",
      "FullnamePlayerIcon",
      "FullnameAudio",
      "AddressText",
      "AddressInput",
      "ErrorAddress",
      "AddressPencilIcon",
      "AddressPlayerIcon",
      "AddressAudio",
      "CityText",
      "CityInput",
      "ErrorCity",
      "CityPencilIcon",
      "CityPlayerIcon",
      "CityAudio",
      "ZipcodeText",
      "ZipcodeInput",
      "ErrorZipcode",
      "ZipcodePencilIcon",
      "ZipcodePlayerIcon",
      "ZipcodeAudio",
      "CommentAudio",
      "CommentAudioSource"
    ]
  }

  connect() {
    this.element[this.identifier] = this

    this.commentId = JSON.parse(this.data.get("commentId"))
    this.commentableId = JSON.parse(this.data.get("commentableId"))
    this.commentableType = this.data.get("commentableType")
    this.approvePath = this.data.get("approvePath")
    this.audioPromises = {}

    var audioAttrs = ["Fullname", "Address", "City", "Zipcode"]
    audioAttrs.map((attr) => {
      if (this[`has${attr}AudioTarget`]) {
        this[`${attr}AudioTarget`].addEventListener("ended", () => {
          this.audioEnded(attr)
        })
      }
    })

    this.reattachAudioElement()
  }

  reattachAudioElement() {
    if (this.hasCommentAudioTarget) {
      if (this.CommentAudioTarget.currentSrc == undefined || this.CommentAudioTarget.currentSrc == "" || this.CommentAudioTarget.currentSrc == null) {
        var audio = $(this.CommentAudioTarget)
        var parent = audio.parent()
        audio.detach()
        $(parent).append(audio)
      }
    }
  }

  commentableUrlBase() {
    return this.commentableType == "Item" ? `/items/${this.commentableId}` : `/projects/${this.commentableId}`
  }

  onAttrTextClick(e) {
    var attr = e.currentTarget.dataset.attr

    this[`${attr}TextTarget`].classList.add("hidden")
    this[`${attr}InputTarget`].classList.remove("hidden")
    this[`${attr}PencilIconTarget`].classList.add("hidden")
    this[`${attr}InputTarget`].focus()
  }

  onAttrInputBlur(e) {
    const attr = $(e.target).closest("div").data("attr")
    const param = $(e.target).closest("div").data("param")
    const url = `${this.commentableUrlBase()}/comments/${this.commentId}`
    const element = document.head.querySelector(`meta[name="csrf-token"]`)
    const csrfToken = element.getAttribute("content")

    if (this[`${attr}TextTarget`].innerHTML !== this[`${attr}InputTarget`].innerHTML) {
      fetch(url, {
        method: "PUT",
        credentials: "same-origin",
        headers:  {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({
          comment: {
            [param]: this[`${attr}InputTarget`].innerHTML,
          }
        })
      })
        .then(response => response.json())
        .then(response => {
          if (response.errors) {
            this[`Error${attr}Target`].classList.remove("hidden")
          } else {
            this[`${attr}TextTarget`].innerHTML = response[param]
            this[`${attr}InputTarget`].innerHTML = response[param]
            this[`${attr}TextTarget`].classList.remove("hidden")
            this[`${attr}PencilIconTarget`].classList.remove("hidden")
            this[`${attr}InputTarget`].classList.add("hidden")
            this[`Error${attr}Target`].classList.add("hidden")
          }
        })
      .catch(console.error)
    } else {
      this[`${attr}TextTarget`].classList.remove("hidden")
      this[`${attr}PencilIconTarget`].classList.remove("hidden")
      this[`${attr}InputTarget`].classList.add("hidden")
      this[`Error${attr}Target`].classList.add("hidden")
    }
  }

  audioPlayPause(e) {
    var attr = $(e.target).closest("a").data("attr")
    var audioElem = this[`${attr}AudioTarget`]
    if (audioElem.paused) {
      this[`${attr}PlayerIconTarget`].classList.remove("fa-play")
      this[`${attr}PlayerIconTarget`].classList.add("fa-pause")
      this.audioPromises[attr] = audioElem.play();
    } else {
      this[`${attr}PlayerIconTarget`].classList.remove("fa-pause")
      this[`${attr}PlayerIconTarget`].classList.add("fa-play")
      if (this.audioPromises[attr] != undefined) {
        this.audioPromises[attr].then(() => {
          audioElem.pause();
        })
      }
    }
  }

  audioEnded(attr) {
    this[`${attr}PlayerIconTarget`].classList.remove("fa-pause")
    this[`${attr}PlayerIconTarget`].classList.add("fa-play")
  }

  handleLiveUpdateData(meetingData) {
    var matchingItem = meetingData.items.find((item) => item.id === this.itemId)

    if (matchingItem) {
      var matchingComment = matchingItem.comments.find((comment) => comment.id === this.commentId)

      if (matchingComment) {
        if (this.hasCommentTextTarget) {
          this.CommentTextTarget.innerHTML = matchingComment.text_html
        }
        if (this.hasCommentInputTarget) {
          this.CommentInputTarget.innerHTML = matchingComment.text_html
        }
        if (this.hasAddressTextTarget) {
          this.FullnameTextTarget.innerHTML = matchingComment.full_name
        }
        if (this.hasAddressInputTarget) {
          this.FullnameInputTarget.innerHTML = matchingComment.full_name
        }
        if (this.hasAddressTextTarget) {
          this.AddressTextTarget.innerHTML = matchingComment.street_address
          this.AddressInputTarget.innerHTML = matchingComment.street_address
          this.CityTextTarget.innerHTML = matchingComment.city
          this.CityInputTarget.innerHTML = matchingComment.city
          this.ZipcodeTextTarget.innerHTML = matchingComment.zip_code
          this.ZipcodeInputTarget.innerHTML = matchingComment.zip_code
        }
        this.DmViewCountTarget.innerHTML = matchingComment.dm_count_text
      }
    }
  }
}
