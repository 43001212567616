import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["Content", "ReadMoreButton"]

  connect() {
    this.truncate()
  }

  truncate() {
    const contentHeight = this.ContentTarget.offsetHeight
    const contentLineHeight = 24
    const contentLines = contentHeight / contentLineHeight

    if (contentLines > 5) {
      this.ContentTarget.classList.add('truncated-overlay')
      this.ReadMoreButtonTarget.classList.remove('hidden')
    }
  }

  onReadMore() {
    this.ContentTarget.classList.remove('truncated-overlay')
    this.ReadMoreButtonTarget.classList.add('hidden')
  }
}