// live_commenting_related
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets () {
    return [
      "ContentSubnavTab",
      "CommentsSubnavTab",
      "VideosAndFilesContent",
      "FormsContent",
      "CommentsTab",
      "CommentsOpenIndicator",
      "CommentsOpenIndicatorText",
      "CommentsOpenIndicatorCountdown",
      "CommentsForm",
      "AskStaffForm",
      "AskStaffTab",
      "AskApplicantForm",
      "AskApplicantTab",
      "StaffResponsesForm",
      "StaffResponsesTab",
      "CommentFormContainer",
      "UnreadCommentsCount",
      "TotalCommentsCount",
      "VoiceCommentModal",
    ]
  }

  tabKeys() {
    return [
      "Comments",
      "AskStaff",
      "AskApplicant",
      "StaffResponses",
    ]
  }

  get modalsController() {
    // force recompile
    var elem = document.getElementById("comment-modals")
    return this.application.getControllerForElementAndIdentifier(elem, "comment-modals")
  }

  initialize() {
    this.countdownTimerInterval = null
    this.itemId = JSON.parse(this.data.get("itemId"))
    this.secondsUntilCommentsDisabled = this.data.get("secondsUntilCommentsDisabled")
    this.isMobileSized = window.innerWidth < 640
    this.showingTabKey = null
    this.commentFormRecaptcha = null
    this.askStaffRecaptcha = null
    this.askApplicantRecaptcha = null

    if (window.location.hash && window.location.hash.indexOf("#comments") >= 0) {
      this.onFormsClick()
    }

    if (this.secondsUntilCommentsDisabled) {
      this.startCountdownTimer()
    }

    this.selectFirstAvailableTab()

    $('.new_comment_submit').on('click', this.commentFormSubmit.bind(this))
  }

  connect() {
    this.element[this.identifier] = this
  }

  selectFirstAvailableTab() {
    let firstTabFound = false

    for (let tabKey of this.tabKeys()) {
      if (this[`has${tabKey}TabTarget`]) {
        if (!firstTabFound) {
          this[`${tabKey}TabTarget`].classList.add("border-l", "border-t", "border-r")
          this[`${tabKey}TabTarget`].classList.remove("lg:border-b", "bg-our-gray-200")
          firstTabFound = true
          this.showingTabKey = tabKey
        } else {
          this[`${tabKey}TabTarget`].classList.remove("border-l", "border-t", "border-r")
          this[`${tabKey}TabTarget`].classList.add("lg:border-b", "bg-our-gray-200")
        }
      }
    }
  }

  onVideosAndFilesClick(e) {
    if (e) {
      e.preventDefault()
    }

    history.replaceState(null, null, ' ');
    this.ContentSubnavTabTarget.classList.add("bg-primary-color-darker", "font-semibold")
    this.CommentsSubnavTabTarget.classList.remove("bg-primary-color-darker", "font-semibold")
    let item = $("#videos-and-files-container")[0] // what we want to scroll to
    let wrapper = $("#content-pane")[0] // the wrapper we will scroll inside
    var offset = this.isMobileSized ? -100 : 40
    let count = item.offsetTop - wrapper.scrollTop - offset // any extra distance from top ex. 60
    if (this.isMobileSized) {
      window.scrollBy({top: count, left: 0, behavior: 'smooth'})
    } else {
      wrapper.scrollBy({top: count, left: 0, behavior: 'smooth'})
    }
  }

  onFormsClick(e) {
    if (e) {
      e.preventDefault()
    }
    history.replaceState(null, null, "#comments")
    this.ContentSubnavTabTarget.classList.remove("bg-primary-color-darker", "font-semibold")
    this.CommentsSubnavTabTarget.classList.add("bg-primary-color-darker", "font-semibold")
    let item = $("#comments-tab-content")[0] // what we want to scroll to
    let wrapper = $("#content-pane")[0] // the wrapper we will scroll inside
    var offset = this.isMobileSized ? -120 : 80
    let count = item.offsetTop - wrapper.scrollTop - offset // any extra distance from top ex. 60
    if (this.isMobileSized) {
      $('html, body').animate({ scrollTop: $("#forms").offset().top - 120 });
    } else {
      wrapper.scrollBy({top: count, left: 0, behavior: 'smooth'})
    }
  }

  smoothScrollIntoView(selector) {
    var offset = $(selector).offset()
    if (offset) {
      $('html, body').animate({ scrollTop: $(selector).offset().top - 80 });
    }
  }

  hideErrors () {
    $('.item-form-error-container.staff').hide();
    $('.item-form-error-container.applicant').hide();
    $('.item-form-error-container.comment').hide();
  }

  resetRecaptchas() {
    if (this.showingTabKey == "Comments" && this.commentFormRecaptcha !== null) {
      grecaptcha.reset(this.commentFormRecaptcha);
    }
    if (this.showingTabKey == "AskStaff" && this.askStaffRecaptcha !== null) {
      grecaptcha.reset(this.askStaffRecaptcha);
    }
    if (this.showingTabKey == "AskApplicant" && this.askApplicantRecaptcha !== null) {
      grecaptcha.reset(this.askApplicantRecaptcha);
    }
  }

  openCommentForm() {
    this.CommentFormContainerTarget.classList.remove("hidden")
    let item = $("#new-comment-form-container")[0] // what we want to scroll to
    let wrapper = $("#content-pane")[0] // the wrapper we will scroll inside
    let count = item.offsetTop - wrapper.scrollTop - 180 // any extra distance from top ex. 60
    wrapper.scrollBy({top: count, left: 0, behavior: 'smooth'})
    if (this.commentFormRecaptcha == null) {
      try {
        this.commentFormRecaptcha = grecaptcha.render('recaptcha-comment', {
          'sitekey' : window.recaptchaSiteKey,
          'theme' : 'light',
          'expired-callback': this.resetRecaptchas.bind(this)
        })
      } catch (error) {
        console.log('error: ', error)
        window.Sentry && window.Sentry.captureException(error);
      }
    }
  }

  closeCommentForm() {
    this.CommentFormContainerTarget.classList.add("hidden")
  }

  commentFormSubmit(event) {
    event.preventDefault();
    $('.new_comment_submit').prop('disabled', true);

    var form = $(event.target).parents('form:first')[0];
    var formUrl = form.action;

    // workaround for safari mobile bug... https://stackoverflow.com/questions/49614091/safari-11-1-ajax-xhr-form-submission-fails-when-inputtype-file-is-empty/49827426#49827426
    var $inputs = $('input[type="file"]:not([disabled])', $(form))
    $inputs.each(function(_, input) {
      if (input.files.length > 0) return
      $(input).prop('disabled', true)
    })

    var formData = new FormData($("#new_comment")[0]);

    // related to workaround above
    $inputs.prop('disabled', false)

    try {
      $.ajax({
        url: formUrl,
        data: formData,
        dataType: 'text',
        enctype: 'multipart/form-data',
        contentType: false,
        processData: false,
        cache: false,
        type: 'POST',
        success: (response) => {
          var comment = JSON.parse(response)
          $(form).trigger("reset");
          this.hideErrors()
          $('.new_comment_submit').prop('disabled', false);
          grecaptcha.reset(this.commentFormRecaptcha);
          var appearText

          if (comment.possible_profanity) {
            appearText = "It will not appear on the public page until is has been approved."
          } else if (comment.autopublish_live_comments) {
            appearText = "It has been automatically published and now appears on the public page."
          } else {
            appearText = "It will not appear on the public page until is has been approved."
          }
          $("#form-success-modal-text").html("Your Comment has been submitted.<br/>"+appearText);
          $("#form-success-modal").removeClass("!hidden");
          $("#new-comment-form-container").addClass("hidden")
          this.smoothScrollIntoView(".form-success-modal-container")
        },
        error: (error) => {
          $('.new_comment_submit').prop('disabled', false);

          var errors = JSON.parse(error.responseText);
          var errorHtml = []

          $.each(errors, function(key, value) {
            var errorItem = document.createElement("li")
            errorItem.innerHTML = key.titleize().replace('Text', 'Comment').replace('Base', '') + ' ' + value.join(" & ")
            errorHtml = errorHtml.concat(errorItem.outerHTML);
          })

          this.resetRecaptchas()

          $('.item-form-errors.comment').html(errorHtml.join(''));
          $('.item-form-error-container.comment').show();
          let item = $('.item-form-error-container.comment')[0] // what we want to scroll to
          let wrapper = $("#content-pane")[0] // the wrapper we will scroll inside
          let count = item.offsetTop - wrapper.scrollTop - 80 // any extra distance from top ex. 60
          wrapper.scrollBy({top: count, left: 0, behavior: 'smooth'})
        }
      })
    } catch (e) {
      console.log('exception: ', e)
    }
  }

  onFormTabClick(e) {
    e.preventDefault()

    var key = $(e.target).data("tabKey")
    this.CommentsFormTarget.classList.add("hidden")
    this.AskStaffFormTarget.classList.add("hidden")
    this.AskApplicantFormTarget.classList.add("hidden")
    this.StaffResponsesFormTarget.classList.add("hidden")
    this[`${key}FormTarget`].classList.remove("hidden")
    this.showingTabKey = key

    if (key == "AskStaff" && this.hasAskStaffTabTarget) {
      let item = $("#ask-x-staff")[0] // what we want to scroll to
      let wrapper = $("#content-pane")[0] // the wrapper we will scroll inside
      let count = item.offsetTop - wrapper.scrollTop - 180 // any extra distance from top ex. 60
      wrapper.scrollBy({top: count, left: 0, behavior: 'smooth'})

      if ($("#recaptcha-ask-staff").length && this.askStaffRecaptcha == null) {
        try {
          this.askStaffRecaptcha = grecaptcha.render('recaptcha-ask-staff', {
            'sitekey' : window.recaptchaSiteKey,
            'theme' : 'light',
            'expired-callback': this.resetRecaptchas.bind(this)
          })
        } catch (error) {
          console.log('error: ', error)
          window.Sentry && window.Sentry.captureException(error);
        }
      }
    }

    if (key == "AskApplicant" && this.hasAskApplicantTabTarget) {
      let item = $("#ask-x-applicant")[0] // what we want to scroll to
      let wrapper = $("#content-pane")[0] // the wrapper we will scroll inside
      let count = item.offsetTop - wrapper.scrollTop - 180 // any extra distance from top ex. 60
      wrapper.scrollBy({top: count, left: 0, behavior: 'smooth'})

      if ($("#recaptcha-ask-applicant").length && this.askApplicantRecaptcha == null) {
        try {
          this.askApplicantRecaptcha = grecaptcha.render('recaptcha-ask-applicant', {
            'sitekey' : window.recaptchaSiteKey,
            'theme' : 'light',
            'expired-callback': this.resetRecaptchas.bind(this)
          })
        } catch (error) {
          console.log('error: ', error)
          window.Sentry && window.Sentry.captureException(error);
        }
      }
    }
    if (this.hasCommentsTabTarget) {
      this.CommentsTabTarget.classList.remove("border-l", "border-t", "border-r")
      this.CommentsTabTarget.classList.add("lg:border-b", "bg-our-gray-200")
    }
    if (this.hasAskStaffTabTarget) {
      this.AskStaffTabTarget.classList.remove("border-l", "border-t", "border-r")
      this.AskStaffTabTarget.classList.add("lg:border-b", "bg-our-gray-200")
    }
    if (this.hasAskApplicantTabTarget) {
      this.AskApplicantTabTarget.classList.remove("border-l", "border-t", "border-r")
      this.AskApplicantTabTarget.classList.add("lg:border-b", "bg-our-gray-200")
    }
    if (this.hasStaffResponsesTabTarget) {
      this.StaffResponsesTabTarget.classList.remove("border-l", "border-t", "border-r")
      this.StaffResponsesTabTarget.classList.add("lg:border-b", "bg-our-gray-200")
    }
    this[`${key}TabTarget`].classList.add("border-l", "border-t", "border-r")
    this[`${key}TabTarget`].classList.remove("lg:border-b", "bg-our-gray-200")
  }

  handleLiveUpdateData(meetingData) {
    var matchingItem = meetingData.items.find((item) => item.id === this.itemId)

    if (matchingItem) {
      // set badge count
      var publishedComments = matchingItem.comments.filter((comment) => comment.status === "published")
      var publishedCommentsCount = publishedComments.length
      if (this.hasTotalCommentsCountTarget) {
        $(this.TotalCommentsCountTarget).html(parseInt(publishedCommentsCount))
      }

      // update unread badge count
      if (this.hasUnreadCommentsCountTarget) {
        var readCount = publishedComments.filter((comment) => comment.read).length
        $(this.UnreadCommentsCountTarget).html(publishedCommentsCount - readCount)
      }

      // update comments open indicator or countdown
      if (this.hasCommentsOpenIndicatorTarget) {
        if (matchingItem.live_comments_enabled) {
          this.CommentsOpenIndicatorTarget.classList.remove("hidden")


          if (matchingItem.seconds_until_comments_disabled) {
            this.secondsUntilCommentsDisabled = matchingItem.seconds_until_comments_disabled

            this.CommentsOpenIndicatorTextTarget.classList.add("hidden")
            if (this.hasCommentsOpenIndicatorCountdownTarget) {
              this.CommentsOpenIndicatorCountdownTarget.classList.remove("hidden")
              this.startCountdownTimer()
            }
          } else {
            this.CommentsOpenIndicatorTextTarget.classList.remove("hidden")
            if (this.hasCommentsOpenIndicatorCountdownTarget) {
              this.CommentsOpenIndicatorCountdownTarget.classList.add("hidden")
            }
          }
        } else {
          this.CommentsOpenIndicatorTarget.classList.add("hidden")
          this.CommentsOpenIndicatorTextTarget.classList.remove("hidden")
          if (this.hasCommentsOpenIndicatorCountdownTarget) {
            this.CommentsOpenIndicatorCountdownTarget.classList.add("hidden")
          }
        }
      }
    }
  }

  setSecondsUntilCommentsDisabled(seconds) {
    this.secondsUntilCommentsDisabled = seconds
  }

  startCountdownTimer() {
    if (this.countdownTimerInterval === null) {
      this.countdownTimerInterval = setInterval(() => {
        var disabledAtMin = Math.floor(this.secondsUntilCommentsDisabled / 60.0)
        var disabledAtSec = Math.floor(this.secondsUntilCommentsDisabled - (disabledAtMin * 60.0)).toString().padStart(2, '0')

        if (disabledAtMin <= 0 && disabledAtSec <= 0) {
          clearInterval(this.countdownTimerInterval)
          this.countdownTimerInterval = null
          if (this.hasCommentsOpenIndicatorCountdownTarget) {
            $(this.CommentsOpenIndicatorCountdownTarget).html('0:00')
          }
        } else {
          if (this.hasCommentsOpenIndicatorCountdownTarget) {
            $(this.CommentsOpenIndicatorCountdownTarget).html(disabledAtMin+':'+disabledAtSec)
          }
        }

        this.secondsUntilCommentsDisabled -= 1
      }, 1000)
    }
  }

  openVoiceCommentModal() {
    this.modalsController.openVoiceCommentModal()
  }

  onMeetingTocVideoResize(expand) {
    if (expand) {
      $("#item-subnav").addClass("flex-col")
      $("#item-subnav").removeClass("md:flex-row")
      $(".item-subnav-link").each((_, elem) => {
        $(elem).removeClass("md:border-opacity-0")
      })
    } else {
      $("#item-subnav").removeClass("flex-col")
      $("#item-subnav").addClass("md:flex-row")
      $(".item-subnav-link").each((_, elem) => {
        $(elem).addClass("md:border-opacity-0")
      })
    }
  }

}