// live_commenting_related
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets () {
    return [
      "TurnOnModal",
      "PublishAutomaticallyCheckbox",
      "CancelButton",
      "TurnOffModal",
      "OffCancelButton",
      "TimerModal",
      "CommentsEnabledForInput",
      "CommentsButtonDisabledForInput",
      "TimerPublishAutomaticallyCheckbox",
      "TimerCancelButton",
      "VoiceCommentModal",
      "VoiceCommentTwilioPin"
    ]
  }

  get widgetController() {
    var elem = document.getElementById("live-commenting-widget")
    return this.application.getControllerForElementAndIdentifier(elem, "live-commenting-widget")
  }

  initialize() {
    this.itemId = this.data.get("itemId")
    if (this.itemId) {
      this.itemId = JSON.parse(this.itemId)
    }
    this.liveCommentsEnabled = this.data.get("liveCommentsEnabled")
    if (this.liveCommentsEnabled) {
      this.liveCommentsEnabled = JSON.parse(this.liveCommentsEnabled)
    }
    this.formUrl = this.data.get("formUrl")
    this.itemTwilioPin = this.data.get("itemTwilioPin")
    this.showingTurnOnModal = false
    this.showingTurnOffModal = false
    this.showingTimerModal = false
  }

  connect() {
    this.element[this.identifier] = this
  }

  setState(data) {
    this.itemId = data.itemId
    if (data.liveCommentsEnabled) {
      this.liveCommentsEnabled = data.liveCommentsEnabled
    }
    this.formUrl = data.formUrl
    this.VoiceCommentTwilioPinTarget.innerHTML = data.itemTwilioPin
  }

  showTurnOnModal() {
    this.TurnOnModalTarget.classList.remove("hidden")
    this.showingTurnOnModal = true
    this.showingTurnOffModal = false
    this.showingTimerModal = false
  }

  showTurnOffModal() {
    this.TurnOffModalTarget.classList.remove("hidden")
    this.TimerModalTarget.classList.add("hidden")
    this.showingTurnOnModal = false
    this.showingTurnOffModal = true
    this.showingTimerModal = false
  }

  showTimerModal() {
    this.TimerModalTarget.classList.remove("hidden")
    this.showingTurnOnModal = false
    this.showingTurnOffModal = false
    this.showingTimerModal = true
  }

  hideModals(event) {
    this.TurnOnModalTarget.classList.add("hidden")
    this.TurnOffModalTarget.classList.add("hidden")
    this.TimerModalTarget.classList.add("hidden")
    this.showingTurnOnModal = false
    this.showingTurnOffModal = false
    this.showingTimerModal = false
  }

  turnOnSubmit(e) {
    e.preventDefault()

    this.updateItem({ item: {
      live_comments_enabled: !this.liveCommentsEnabled,
      autopublish_live_comments: this.PublishAutomaticallyCheckboxTarget.checked
    }})
  }

  turnOffSubmit(e) {
    e.preventDefault()

    this.updateItem({ item: { live_comments_enabled: !this.liveCommentsEnabled }})
  }

  timerSubmit(e) {
    e.preventDefault()

    this.updateItem({ item: {
      live_comments_enabled: true,
      live_comments_enabled_for: this.CommentsEnabledForInputTarget.value,
      live_comments_button_disabled_for: this.CommentsButtonDisabledForInputTarget.value,
      autopublish_live_comments: this.TimerPublishAutomaticallyCheckboxTarget.checked
    }})
  }

  openVoiceCommentModal(e) {
    this.VoiceCommentModalTarget.classList.remove("hidden")
  }

  closeVoiceCommentModal(e) {
    this.VoiceCommentModalTarget.classList.add("hidden")
  }

  updateItem(data) {
    fetch(this.formUrl, {
      method: "PUT",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(response => {
        this.widgetController.handleNewData(response)
      })
      .catch(console.error)

    this.hideModals()
    this.widgetController.handleNewData({ items: [{ id: this.itemId, live_comments_enabled: data.item.live_comments_enabled }] })
  }

  handleLiveUpdateData(data) {
    var matchingItem = data.items.find((item) => item.id === this.itemId)

    if (matchingItem) {
      this.liveCommentsEnabled = matchingItem.live_comments_enabled
    }
  }

}