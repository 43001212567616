import { Controller } from "@hotwired/stimulus"
import {enter, leave} from 'el-transition'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ["Menu"]

  connect() {
    this.isOpen = false

    useClickOutside(this)
  }

  clickOutside(event) {
    if (this.isOpen) {
      event.preventDefault()
      this.toggleMenu()
    }
  }

  toggleMenu() {
    if (this.MenuTarget.classList.contains('hidden')) {
      enter(this.MenuTarget)
    } else {
      leave(this.MenuTarget)
    }

    this.isOpen = !this.isOpen
  }
}