// live_commenting_related
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets () {
    return [
      "ToggleContainer",
      "ToggleTextContainer",
      "ToggleTextContent",
      "ToggleTimerContent",
      "ToggleSwitch",
      "TimerButton",
    ]
  }

  get modalsController() {
    var elem = document.getElementById("comment-modals")
    return this.application.getControllerForElementAndIdentifier(elem, "comment-modals")
  }

  initialize() {
    this.itemId = JSON.parse(this.data.get("itemId"))
    this.liveCommentsEnabled = JSON.parse(this.data.get("liveCommentsEnabled"))
    this.secondsUntilCommentsDisabled = this.data.get("secondsUntilCommentsDisabled")
    this.countdownTimerInterval = null

    setTimeout(() => {
      this.updateModalsControllerState()
    }, 100)

    if (this.secondsUntilCommentsDisabled) {
      this.startCountdownTimer()
    }
  }

  connect() {
    this.element[this.identifier] = this
  }

  updateModalsControllerState() {
    this.modalsController.setState({
      itemId: this.itemId,
      liveCommentsEnabled: this.liveCommentsEnabled,
    })
  }

  onToggleClick() {
    if (!this.liveCommentsEnabled) {
      this.modalsController.showTurnOnModal()
    } else {
      this.modalsController.showTurnOffModal()
    }
  }

  onTimerClick() {
    this.modalsController.showTimerModal()
  }

  handleLiveUpdateData(data) {
    this.handleNewData(data)
  }

  handleNewData(meetingData) {
    var matchingItem = meetingData.items.find((item) => item.id === this.itemId)

    if (matchingItem) {
      if (matchingItem.live_comments_enabled) {
        this.TimerButtonTarget.classList.remove("hidden")
        this.ToggleContainerTarget.classList.remove("w-14", "sm:w-16", "lg:w-20")
        this.ToggleContainerTarget.classList.add("w-10", "sm:w-16", "lg:w-20")
        this.ToggleTextContainerTarget.classList.remove("translate-x-3", "lg:translate-x-4", "!text-black")
        this.ToggleTextContainerTarget.classList.add("translate-x-4", "sm:translate-x-6", "lg:translate-x-9")
        $(this.ToggleTextContentTarget).html("On")
        this.ToggleSwitchTarget.classList.remove("translate-x-10", "sm:translate-x-11", "lg:translate-x-14", "!bg-gray-400")
        this.ToggleSwitchTarget.classList.add("translate-x-0")
        this.liveCommentsEnabled = true
        if (matchingItem.seconds_until_comments_disabled) {
          var disabledAtMin = Math.floor(matchingItem.seconds_until_comments_disabled / 60.0)
          var disabledAtSec = Math.floor(matchingItem.seconds_until_comments_disabled - (disabledAtMin * 60.0)).toString().padStart(2, '0')
          this.ToggleTextContainerTarget.classList.remove('translate-x-4', 'sm:translate-x-6', 'lg:translate-x-9')
          this.ToggleTextContainerTarget.classList.add('translate-x-3', 'sm:translate-x-5', 'lg:translate-x-7')
          this.ToggleTextContentTarget.classList.add("hidden")
          this.ToggleTimerContentTarget.classList.remove("hidden")
          this.ToggleTimerContentTarget.classList.add("sm:text-xs")
          this.ToggleSwitchTarget.classList.add("w-2", "sm:w-3")
          this.ToggleSwitchTarget.classList.remove("w-3", "sm:w-4")
          $(this.ToggleTimerContentTarget).html(disabledAtMin+':'+disabledAtSec)
          this.secondsUntilCommentsDisabled = matchingItem.seconds_until_comments_disabled
          this.startCountdownTimer()
        }
      } else {
        this.ToggleContainerTarget.classList.remove("w-10", "sm:w-16", "lg:w-20")
        this.ToggleContainerTarget.classList.add("w-14", "sm:w-16", "lg:w-20")
        this.ToggleTextContainerTarget.classList.add("translate-x-2", "sm:translate-x-3", "lg:translate-x-4", "!text-black")
        this.ToggleTextContainerTarget.classList.remove("translate-x-3", "translate-x-4", "sm:translate-x-5", "sm:translate-x-6", "lg:translate-x-7", 'lg:translate-x-8', "lg:translate-x-9")
        $(this.ToggleTextContentTarget).html("Off")
        this.ToggleSwitchTarget.classList.add("translate-x-10", "sm:translate-x-11", "lg:translate-x-14", "!bg-gray-400")
        this.ToggleSwitchTarget.classList.remove("translate-x-0")
        this.TimerButtonTarget.classList.add("hidden")
        this.liveCommentsEnabled = false
        if (matchingItem.live_comments_disabled_at === null) {
          this.ToggleTextContentTarget.classList.remove("hidden")
          this.ToggleTimerContentTarget.classList.add("hidden")
          this.ToggleTimerContentTarget.classList.remove("sm:text-xs")
          this.ToggleSwitchTarget.classList.remove("w-2", "sm:w-3")
          this.ToggleSwitchTarget.classList.add("w-3", "sm:w-4")
        }
      }
    }
  }

  setSecondsUntilCommentsDisabled(seconds) {
    this.secondsUntilCommentsDisabled = seconds
  }

  startCountdownTimer() {
    if (this.countdownTimerInterval === null) {
      this.countdownTimerInterval = setInterval(() => {
        var disabledAtMin = Math.floor(this.secondsUntilCommentsDisabled / 60.0)
        var disabledAtSec = Math.floor(this.secondsUntilCommentsDisabled - (disabledAtMin * 60.0)).toString().padStart(2, '0')

        if (!this.liveCommentsEnabled || (disabledAtMin <= 0 && disabledAtSec <= 0)) {
          clearInterval(this.countdownTimerInterval)
          this.countdownTimerInterval = null
          $(this.ToggleTimerContentTarget).html('0:00')
        } else {
          $(this.ToggleTimerContentTarget).html(disabledAtMin+':'+disabledAtSec)
        }

        this.secondsUntilCommentsDisabled -= 1
      }, 1000)
    }
  }

}