$(document).ready(function () {
  $('.field_with_errors:has(label)').
    css('margin-top', '32px').
    css('margin-bottom', '8px');

  if ($(".notice").length) {
    setTimeout(() => {
      $(".notice").addClass("hidden")
    }, 3000)
  }

  // a comment here
})
