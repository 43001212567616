// From:
// https://mikerogers.io/2019/09/16/using-actioncable-and-stimulus-to-remotely-update-partials-in-rails-6
window.replaceElementWithPartial = function(element, html, replaceEntireElement = true) {
  let newBody = window._parseHTMLResponse(html);

  // NOTE: I spent some time trying to get this to work for static youtube player embed, but it was still getting replaced below
  // Replace all data-turbolinks-permanent elements in the body with what was there
  // previously. This is useful for elements the user might interact with, such
  // as forms or dropdowns.
  // let permanentNodes = element.querySelectorAll("[data-permanent]");
  // permanentNodes.forEach(function(elem){
  //   var oldElement = newBody.querySelector("[data-permanent]")
  //   oldElement.parentNode.replaceChild(elem, oldElement);
  // });

  // Remove all the current nodes from our element.
  while( element.firstChild ) { element.removeChild( element.firstChild ); }

  // When we're sending a new partial, which is a full replacement of our
  // element & not just a group of children.
  if (replaceEntireElement === true) {
    element.replaceWith(newBody)
  } else {
    if( newBody.childElementCount === 1){
      while( newBody.firstElementChild.firstChild ) { element.appendChild( newBody.firstElementChild.firstChild ); }
    } else {
      // Append the new nodes.
      while( newBody.firstChild ) { element.appendChild( newBody.firstChild ); }
    }
  }
}

// From: https://stackoverflow.com/a/42658543/445724
// using .innerHTML= is risky. Instead we need to convert the HTML received
// into elements, then append them.
// It's wrapped in a <template> tag to avoid invalid (e.g. a block starting with <tr>)
// being mutated inappropriately.
window._parseHTMLResponse = function(responseHTML) {
  let parser = new DOMParser();
  let responseDocument = parser.parseFromString( `<template>${responseHTML}</template>` , 'text/html');
  let parsedHTML = responseDocument.head.firstElementChild.content;
  return parsedHTML;
}
