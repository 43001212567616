// live_commenting_related
import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  initialize() {
    this.subscription = null
    this.subscriptionKey = this.data.get("subscriptionKey")
    this.meetingId = JSON.parse(this.data.get("meetingId"))
  }

  connect() {
    this.element[this.identifier] = this

    if (this.subscription === null) {
      this.subscription = consumer.subscriptions.create({
        channel: "MeetingDataChannel",
        key: this.subscriptionKey
      },
      {
        connected: function(data) {
          Sockets.MeetingDataChannel = this
        },
        disconnected: function(data) {
          Sockets.MeetingDataChannel = null
        },
        rejected: (data) => {
          console.log('md rejected data: ', data)
        },
        received: (data) => {
          this.handleUpdate(data)
        }
      })
    }
  }

  disconnect() {
    this.subscription.unsubscribe()
    this.subscription = null
  }

  handleUpdate(data) {
    this.sendDataToControllersWithName("live-commenting-widget", data)
    this.sendDataToControllersWithName("comment-modals", data)
    this.sendDataToControllersWithName("item-subnav-tabs", data)
    this.sendDataToControllersWithName("item-unread-comments-notification", data)
    this.sendDataToControllersWithName("comments-list", data)
    this.sendDataToControllersWithName("comment", data)
  }

  sendDataToControllersWithName(controllerName, data) {
    $(`[data-controller*='${controllerName}']`).each((_, e) => {
      var elem = document.getElementById($(e).attr('id'))
      var controller = this.application.getControllerForElementAndIdentifier(elem, controllerName)

      if (controller) {
        controller.handleLiveUpdateData(data)
      }
    })
  }

  // sent via socket-js-polling-fallback controller
  handleFallbackData(data) {
    this.handleUpdate(data)
  }

}