import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets () {
    return [
      "CollapseIcon",
      "ExpandIcon",
      "ContentContainer",
      "Iframe"
    ]
  }

  initialize() {
    this.attachmentsCount = JSON.parse(this.data.get("attachmentsCount"))
    this.index = JSON.parse(this.data.get("index"))
    this.embedUrl = this.data.get("embedUrl")
    this.isOpen = false

    this.setBorderClasses()
  }

  connect() {
    this.element[this.identifier] = this
  }

  onClick(e, scroll = true) {
    this.isOpen = !this.isOpen

    if (this.isOpen) {
      this.ExpandIconTarget.classList.add("hidden")
      this.CollapseIconTarget.classList.remove("hidden")
      this.ContentContainerTarget.classList.remove("hidden")
      setTimeout(() => {
        $(this.IframeTarget).attr('src', this.embedUrl)
        if (scroll) {
          e.target.scrollIntoView()
        }
      }, 100)
    } else {
      this.ExpandIconTarget.classList.remove("hidden")
      this.CollapseIconTarget.classList.add("hidden")
      this.ContentContainerTarget.classList.add("hidden")
      $(this.IframeTarget).attr("src", "")
    }
  }

  collapse() {
    this.isOpen = false
    this.ExpandIconTarget.classList.remove("hidden")
    this.CollapseIconTarget.classList.add("hidden")
    this.ContentContainerTarget.classList.add("hidden")
    $(this.IframeTarget).attr("src", "")
  }

  setBorderClasses() {
    // check for rounded top
    if ((this.index == 0) || (this.index - 1 == this.isOpen)) {
      this.element.classList.add('rounded-t')
    } else {
      this.element.classList.remove("rounded-t")
    }

    // check for border bottom (only last item)
    if (this.attachmentsCount == this.index + 1) {
      this.element.classList.add('border-b')
    } else {
      this.element.classList.remove('border-b')
    }

    // check for rounded bottom
    if (this.attachmentsCount == this.index + 1 && this.isOpen != this.index) {
      this.element.classList.add('rounded-b')
    } else {
      this.element.classList.remove("rounded-b")
    }
  }

}