import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ["TocItem", "FirstTocItem"]
  }

  connect() {
    if (this.hasFirstTocItemTarget) {
      this.openFirstItem()
    }
  }

  openFirstItem() {
    setTimeout(function() {
      if (this.hasFirstTocItemTarget) {
        this.openResourceFile(this.FirstTocItemTarget.dataset.attachmentId)
      }
    }.bind(this), 500)
  }

  collapseAllOpenResourceFiles() {
    this.TocItemTargets.map((toc_item) => {
      var attachmentId = toc_item.dataset.attachmentId
      this.collapseResourceFile(attachmentId)
    })
  }

  onTocItemClick(e) {
    e.preventDefault()
    e.stopPropagation()

    var elem = e.target

    if (elem.nodeName == "SPAN") {
      elem = $(elem).parent()[0]
    }

    var attachmentId = elem.dataset.attachmentId

    this.openResourceFile(attachmentId)
  }

  openResourceFile(attachmentId) {
    this.collapseAllOpenResourceFiles()

    var resourceFilesItem = this.resourceFilesItem(attachmentId)
    var e = { target: resourceFilesItem }
    var controller = this.resourceFilesController(attachmentId)

    if (controller) {
      controller.onClick(e, false)
    }
  }

  collapseResourceFile(attachmentId) {
    if (this.resourceFilesController(attachmentId)) {
      this.resourceFilesController(attachmentId).collapse()
    }
  }

  resourceFilesItem(attachmentId) {
    return document.querySelectorAll(`[data-resource-files-attachment-id="${attachmentId}"]`)[0]
  }

  resourceFilesController(attachmentId) {
    return this.application.getControllerForElementAndIdentifier(this.resourceFilesItem(attachmentId), "resource-files")
  }

}