// live_commenting_related
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    this.longPollInterval = null
    this.periodicSocketCheckInterval = null
    this.pollingFrequency = null
    this.onReadyDelay = 5000
    this.meetingId = JSON.parse(this.data.get("meetingId"))
    this.subscriptionKey = this.data.get("subscriptionKey")

    if (this.subscriptionKey.includes("_admin") || this.subscriptionKey.includes("_dm_")) {
      // random value between 5-10 sec
      this.pollingFrequency = Math.floor(Math.random() * (10000 - 5000) + 5000)
    } else {
      // random value between 45-60 sec
      this.pollingFrequency = Math.floor(Math.random() * (60000 - 45000) + 45000)
    }

    // always run this to check for web socket coming or going
    this.startPeriodicSocketCheck()
  }

  connect() {
    this.element[this.identifier] = this

    setTimeout(() => {
      if (Sockets.MeetingDataChannel) {
        // do nothing, websocket present
      } else {
        this.initPollingUpdates()
      }
    }, this.onReadyDelay)
  }

  disconnect() {
    clearInterval(this.longPollInterval)
    clearInterval(this.periodicSocketCheckInterval)
    this.longPollInterval = null
    this.periodicSocketCheckInterval = null
  }

  initPollingUpdates() {
    this.longPollInterval = setInterval(() => {
      if (Sockets.MeetingDataChannel) {
        // found websocket connected after initial polling started
        clearInterval(this.longPollInterval)
        this.longPollInterval = null
      } else {
        this.refetchData()
      }
    }, this.pollingFrequency)
  }

  startPeriodicSocketCheck() {
    this.periodicSocketCheckInterval = setInterval(() => {
      if (Sockets.MeetingDataChannel) {
        // do nothing. socket found
      } else {
        this.initPollingUpdates()
      }
    }, this.pollingFrequency)
  }

  refetchData() {
    this.updateMeetingData()
    this.updatePartials()
  }

  updateMeetingData() {
    fetch(`/meetings/${this.meetingId}/live_update`, {
      method: "GET",
      credentials: "same-origin",
      headers:  {},
    })
      .then(response => response.json())
      .then(response => {
        this.handleMeetingData(response)
      })
      .catch(console.error)
  }

  handleMeetingData(data) {
    this.sendDataToControllersWithName("meeting-data-socket", data)
  }

  updatePartials() {
    this.sendDataToControllersWithName("meeting-toc-socket")
    this.sendDataToControllersWithName("live-comment-banner-socket")
    this.sendDataToControllersWithName("live-comment-timer-sync-socket")
  }

  sendDataToControllersWithName(controllerName, data) {
    $(`[data-controller*='${controllerName}']`).each((_, e) => {
      var elem = document.getElementById($(e).attr('id'))
      var controller = this.application.getControllerForElementAndIdentifier(elem, controllerName)

      if (controller) {
        controller.handleFallbackData(data)
      }
    })
  }

}