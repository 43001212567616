// YT Iframe Embed API reference:
// https://developers.google.com/youtube/iframe_api_reference

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets () {
    return [
    ]
  }

  initialize() {
    this.videoId = this.data.get("videoId")
    this.cuepoints = JSON.parse(this.data.get("cuepoints"))
    this.player = null
    this.isPlaying = false

    window.onYouTubeIframeAPIReady = this.initPlayer.bind(this)
    this.loadYtScript()
  }

  loadYtScript() {
    var tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  initPlayer() {
    this.player = new window.YT.Player('yt_player', {
      height: '100%',
      width: '100%',
      videoId: this.videoId,
      events: {
        'onStateChange': this.onPlayerStateChange.bind(this)
      }
    });
  }

  onPlayerStateChange(data) {
    if (data == 1) {
      this.isPlaying = true
    } else {
      this.isPlaying = false
    }
  }

  onCuepointClick(e) {
    e.preventDefault()

    var cuepointId = $(e.target).data("cuepointId")
    var cuepoint = this.cuepoints.find((c) => c.id === cuepointId.toString())
    this.player.seekTo(cuepoint.seconds_start)
  }

}