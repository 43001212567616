import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return [
      "CurrentUserIcon",
      "CurrentUserLink",
      "LogoutLink",
    ]
  }

  initialize() {
    this.isShowingDropdown = false

    if (this.hasCurrentUserIconTarget) {
      $(this.CurrentUserIconTarget).on("mouseenter", this.onIconHover.bind(this))
      $(this.CurrentUserIconTarget).on("mouseleave", this.onIconBlur.bind(this))
    }
  }

  connect() {
    this.element[this.identifier] = this
  }

  displayDropdown() {
    if (this.isShowingDropdown) {
      this.CurrentUserLinkTarget.classList.remove("opacity-0", "invisible")
      this.CurrentUserLinkTarget.classList.add("opacity-1", "visible")
      this.LogoutLinkTarget.classList.remove("opacity-0", "invisible")
      this.LogoutLinkTarget.classList.add("opacity-1", "visible")
    } else {
      this.CurrentUserLinkTarget.classList.add("opacity-0", "invisible")
      this.CurrentUserLinkTarget.classList.remove("opacity-1", "visible")
      this.LogoutLinkTarget.classList.add("opacity-0", "invisible")
      this.LogoutLinkTarget.classList.remove("opacity-1", "visible")
    }
  }

  onIconHover(e) {
    this.isShowingDropdown = true
    this.displayDropdown()
  }

  onIconBlur(e) {
    this.isShowingDropdown = false
    this.displayDropdown()
  }

}