import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ["textInput", "wordCount", "wordsLeftCount"]
  }

  initialize() {
    this.wordLimit = this.data.get("wordLimit")
  }

  onChange(e) {
    var words = e.target.value.match(/\S+/g) || [];

    this.wordCountTarget.innerHTML = words.length
    this.wordsLeftCountTarget.innerHTML = this.wordLimit - words.length
  }
}