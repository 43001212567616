import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets () {
    return [
      "OpenButton",
      "FormContainer",
      "SubscribedMessage",
      "Form",
      "EmailSubscriptionText",
    ]
  }

  initialize() {
    this.isOpen = JSON.parse(this.data.get("isOpen"))
    this.subscriptionEmail = this.data.get("subscriptionEmail")
    this.subscriptionId = this.data.get("subscriptionEmail")
    this.recaptchaId = null

    if (this.subscriptionEmail !== '') {
      this.SubscribedMessageTarget.classList.remove("hidden")
      // wait for recaptcha library to load
      setTimeout(() => {
        this.onOpen()
      }, 500)
    } else {
      this.FormTarget.classList.remove("hidden")
    }
  }

  connect() {
    this.element[this.identifier] = this
  }

  onOpen() {
    this.OpenButtonTarget.classList.add("hidden")
    this.FormContainerTarget.classList.remove("hidden")
    this.renderRecaptcha()
  }

  onClose(e) {
    e.preventDefault()

    this.OpenButtonTarget.classList.remove("hidden")
    this.FormContainerTarget.classList.add("hidden")
  }

  smoothScrollIntoView(selector) {
    var offset = $(selector).offset()
    if (offset) {
      $('html, body').animate({ scrollTop: $(selector).offset().top - 80 });
    }
  }

  renderRecaptcha() {
    if (this.recaptchaId == null) {
      try {
        this.recaptchaId = window.grecaptcha.render('recaptcha-subscribe', {
          'sitekey' : window.recaptchaSiteKey,
          'theme' : 'light',
          'expired-callback': this.resetRecaptcha.bind(this)
        })
      } catch (error) {
        console.log('error: ', error)
        window.Sentry && window.Sentry.captureException(error);
      }
    }
  }

  resetRecaptcha() {
    if ($('#recaptcha-subscribe').length > 0) {
      grecaptcha.reset(this.recaptchaId);
    }
  }

  onSubmit(event) {
    event.preventDefault();
    $(event.target).prop('disabled', true);
    var form = $(event.target).parents('form:first')[0];
    var formData = $(form).serializeArray()
    var data = {}
    $.each(formData, function () {
      if (data[this.name]) {
        if (!data[this.name].push) {
          data[this.name] = [data[this.name]];
        }
        data[this.name].push(this.value || '');
      } else {
        if (this.name.indexOf("[") > 0) {
          var nameParts = this.name.split("[")
          data[nameParts[0]] = {}
          data[nameParts[0]][nameParts[1].slice(0,-1)] = this.value || ''
        } else {
          data[this.name] = this.value || '';
        }
      }
    });
    var formUrl = form.action;

    fetch(formUrl, {
      method: "POST",
      credentials: "same-origin",
      headers:  {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(response => {
        if (response.errors) {
          console.log('response.errors: ', response.errors)
          $(event.target).prop('disabled', false);

          var errorHtml = []

          $.each(response.errors, function(key, value) {
            errorHtml = errorHtml.concat('<li>'+key.titleize().replace('Text', 'Comment').replace('Base', '') + ' ' + value.join(" & ")+'</li>');
          })

          this.resetRecaptcha()
          $('.item-form-errors.subscribe').html(errorHtml.join(''));
          $('.item-form-error-container.subscribe').show();
        } else {
          $(form).trigger("reset");
          $(event.target).prop('disabled', false);
          grecaptcha.reset(this.recaptchaId);

          $("#form-success-modal-text").html("You have been subscribed.<br/>You will be notified when this item has a decision.");
          $("#form-success-modal").removeClass("!hidden");
          this.smoothScrollIntoView(".form-success-modal-container")
          this.subscribedEmail = response.email
          this.subscriptionId = response.id
          var editForm = $("#edit_subscription")
          editForm.attr('action', editForm.attr('action') + this.subscriptionId)
          this.FormTarget.classList.add("hidden")
          $(this.EmailSubscriptionTextTarget).html(this.subscribedEmail)
          this.SubscribedMessageTarget.classList.remove("hidden")
          this.onOpen()
        }
      })
      .catch(console.error)
  }

}