// live_commenting_related
import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  get modalsController() {
    var elem = document.getElementById("comment-modals")
    return this.application.getControllerForElementAndIdentifier(elem, "comment-modals")
  }

  initialize() {
    this.subscription = null
    this.subscriptionKey = this.data.get("subscriptionKey")
    this.path = this.data.get("path")
    this.itemId = JSON.parse(this.data.get("itemId"))
    this.itemTwilioPin = this.data.get("itemTwilioPin") // used in comment-modals controller, but sent from here

    setTimeout(() => {
      this.updateModalsControllerState()
    }, 100)
  }

  connect() {
    this.element[this.identifier] = this

    if (this.subscription === null) {
      this.subscription = consumer.subscriptions.create({
        channel: "LiveCommentBannerChannel",
        key: this.subscriptionKey
      },
      {
        connected: function(data) {
          Sockets.LiveCommentBannerChannel = this
        },
        disconnected: function(data) {
          Sockets.LiveCommentBannerChannel = null
        },
        rejected: (data) => {
          console.log('lcbc rejected data: ', data)
        },
        received: (data) => {
          window.replaceElementWithPartial(this.element.children[0], data.html)
        }
      })
    }
  }

  disconnect() {
    this.subscription.unsubscribe()
    this.subscription = null
  }

  updateModalsControllerState() {
    this.modalsController.setState({
      itemId: this.itemId,
      liveCommentsEnabled: this.liveCommentsEnabled,
      formUrl: this.path,
      itemTwilioPin: this.itemTwilioPin
    })
  }

  refetchAndRender() {
    fetch(`${this.path}/partials?partial_name=items/comment_banner`, {
      method: "GET",
      credentials: "same-origin",
      headers:  {},
    })
      .then(response => response.text())
      .then(html => {
        window.replaceElementWithPartial(this.element.children[0], html)
      })
      .catch(console.error)
  }

  // sent via socket-js-polling-fallback controller
  handleFallbackData() {
    this.refetchAndRender()
  }

}