// live_commenting_related
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets () {
    return [
    ]
  }

  initialize() {
    this.itemId = JSON.parse(this.data.get("itemId"))
    this.isEmpty = JSON.parse(this.data.get("isEmpty"))
    this.existingCommentIds = []
    this.newCommentsCount = 0

    $(".comment-item").each((_, elem) => {
      if ($(elem).data("commentId")) {
        this.existingCommentIds.push($(elem).data("commentId"))
      }
    })
  }

  connect() {
    this.element[this.identifier] = this
  }

  handleLiveUpdateData(meetingData) {
    var matchingItem = meetingData.items.find((item) => item.id === this.itemId)

    if (matchingItem) {
      if (this.isEmpty) {
        this.updateEmptyCommentsText(meetingData, matchingItem)
      }
      this.addNewComments(matchingItem)
      this.removeComments(matchingItem)
    }
  }

  addNewComments(item) {
    var itemCommentIds = item.comments.map((comment) => comment.id)
    var newCommentIds = itemCommentIds.filter((cid) => !this.existingCommentIds.includes(cid))

    if (newCommentIds.length) {
      this.newCommentsCount = newCommentIds.length

      item.comments.filter((comment) => newCommentIds.includes(comment.id)).map((newComment) => {
        this.fetchAndAddNewCommentPartial(item.id, newComment)
      })

      this.isEmpty = false
    }
  }

  removeComments(item) {
    var itemCommentIds = item.comments.map((comment) => comment.id)
    var removingComments = this.existingCommentIds.filter((cid) => !itemCommentIds.includes(cid))

    removingComments.map((removingComment) => {
      $("[data-comment-id='" + removingComment + "']").remove()
      this.existingCommentIds = this.existingCommentIds.filter((cid) => cid !== removingComment)
    })
  }

  fetchAndAddNewCommentPartial(itemId, comment) {
    fetch(`/items/${itemId}/comments/${comment.id}/partials?partial_name=shared/comment`, {
      method: "GET",
      credentials: "same-origin",
      headers:  {},
    })
      .then(response => response.text())
      .then(html => {
        var listElem = $(this.element)
        listElem.find("#no-comments-placeholder").remove()
        listElem.prepend(html)
        this.existingCommentIds.push(comment.id)
      })
      .catch(console.error)
  }

  updateEmptyCommentsText(meeting, item) {
    if ($("#no-comments-placeholder").length) {
      if (!item.commentable) {
        $("#no-comments-placeholder").html("Comments are not being accepted")
      } else if (item.live_comments_enabled || meeting.commentable) {
        $("#no-comments-placeholder").html("No comments yet...")
      } else {
        $("#no-comments-placeholder").html("Comment period closed. No comments were received.")
      }
    }
  }

}