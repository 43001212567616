import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {

  static get targets() {
    return [
      "Form", "ReasonOtherContainer", "ReasonOtherInput"
    ]
  }

  connect() {
    this.element[this.identifier] = this
  }

  onReasonChange(e) {
    if ((e.target && e.target.value === "Other") || e === "Other") {
      this.ReasonOtherContainerTarget.classList.remove("hidden")
    } else {
      if (this.hasReasonOtherContainerTarget) {
        this.ReasonOtherContainerTarget.classList.add("hidden")
      }
      this.ReasonOtherInputTarget.value = ""
    }
  }

  submitRejectModal(e) {
    e.target.innerHTML = "Please wait..."
    e.target.disabled = true

    this.FormTarget.requestSubmit()
  }

}