import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["Heading", "Link"]

  connect() {
    this.ticking = false
    this.scrollTimeout = null
    this.makeActiveLink = null
    this.headingPositions = []
    this.activeClassList = ["!text-black-500", "!hover:text-black-900", "font-semibold"]
    this.scrollOffset = 20 + 5 // add 5 pixels to account for slight variation

    window.addEventListener('scroll', this.scroll.bind(this), { passive: true })

    setTimeout(() => {
      this.HeadingTargets.map((heading, index) => this.headingPositions[index] = heading.getBoundingClientRect().top)
    }, 750)
  }

  disconnect() {
    window.removeEventListener('scroll', this.scroll, { passive: true })
  }

  makeActive(e) {
    this.makeActiveLink = e.target
  }

  onScrollEnd(e) {
    if (this.makeActiveLink) {
      this.LinkTargets.map((link) => link.classList.remove(...this.activeClassList))
      this.makeActiveLink.classList.add(...this.activeClassList)
      this.makeActiveLink = null
    }
  }

  scroll() {
    if (this.makeActiveLink) {
      window.clearTimeout(this.scrollTimeout)
      this.scrollTimeout = setTimeout(this.onScrollEnd.bind(this), 66)
    }

    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        this.updateActiveLink(window.scrollY)
        this.ticking = false
      })
    }

    this.ticking = true
  }

  updateActiveLink(scrollPosition) {
    this.LinkTargets.map((_, index) => {
      if ((this.headingPositions[index] - this.scrollOffset) - scrollPosition <= 0) {
        this.LinkTargets.map((link) => link.classList.remove(...this.activeClassList))
        this.LinkTargets[index].classList.add(...this.activeClassList)
      }
    })
  }

}