import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ["ShowFormButton", "Form", "SubmitButton"]
  }

  connect() {
    this.element[this.identifier] = this
  }

  showReplyForm(e) {
    e.preventDefault()
    this.ShowFormButtonTarget.classList.add("hidden")
    this.FormTarget.classList.remove("hidden")
  }

  hideReplyForm(e) {
    e.preventDefault()
    this.ShowFormButtonTarget.classList.remove("hidden")
    this.FormTarget.classList.add("hidden")
  }

  onTextInput(e) {
    if (e.target.value.length > 0) {
      this.SubmitButtonTarget.disabled = false
    } else {
      this.SubmitButtonTarget.disabled = true
    }
  }

}
